@import "~bootstrap/scss/bootstrap";

//Small tablets and large smartphones (landscape views)
$screen-sm-min:576px;
//Small tablets (portrait view)
$screen-md-min:768px;
//Tablets and small desktops
$screen-lg-min:992px;
// Large Tablets and desktops
$screen-xl-min:1200px;
//Mixins
$screen-xxl-min:1400px;

//Small 
@mixin sm{
    @media(min-width:#{$screen-sm-min}){
        @content;
    }
}
//Medium 
@mixin md{
    @media(min-width:#{$screen-md-min}){
        @content;
    }
}
//Large 
@mixin lg{
    @media(min-width:#{$screen-lg-min}){
        @content;
    }
}

@mixin xl{
    @media(min-width:#{$screen-xl-min}){
        @content;
    }
}

@mixin xxl{
    @media(min-width:#{$screen-xxl-min}){
        @content;
    }
}

@font-face {
    font-family: 'Montserrat';
    src: url('../Assets/fonts/Montserrat-Regular.eot');
    src: url('../Assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../Assets/fonts/Montserrat-Regular.woff') format('woff'),
        url('../Assets/fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../Assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../Assets/fonts/Montserrat-Medium.eot');
    src: url('../Assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../Assets/fonts/Montserrat-Medium.woff') format('woff'),
        url('../Assets/fonts/Montserrat-Medium.ttf') format('truetype'),
        url('../Assets/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../Assets/fonts/Montserrat-Bold.eot');
    src: url('../Assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../Assets/fonts/Montserrat-Bold.woff') format('woff'),
        url('../Assets/fonts/Montserrat-Bold.ttf') format('truetype'),
        url('../Assets/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Averta-Regular";
    src: url('../Assets/fonts/Averta-Regular.eot');
    src: url('../Assets/fonts/Averta-Regular.eot?#iefix') format('embedded-opentype'),
        url('../Assets/fonts/Averta-Regular.woff2') format('woff2'),
        url('../Assets/fonts/Averta-Regular.woff') format('woff'),
        url('../Assets/fonts/Averta-Regular.ttf') format('truetype'),
        url('../Assets/fonts/Averta-Regular.svg#Montserrat-Bold') format('svg');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'charterbold_italic';
    src: url('../Assets/fonts/charter_bold_italic-webfont.eot');
    src: url('../Assets/fonts/charter_bold_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../Assets/fonts/charter_bold_italic-webfont.woff') format('woff'),
         url('../Assets/fonts/charter_bold_italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'charterbold';
         src: url('../Assets/fonts/charter_bold-webfont.eot');
         src: url('../Assets/fonts/charter_bold-webfont.eot?#iefix') format('embedded-opentype'),
              url('../Assets/fonts/charter_bold-webfont.woff') format('woff'),
              url('../Assets/fonts/charter_bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'charteritalic';
         src: url('../Assets/fonts/charter_italic-webfont.eot');
         src: url('../Assets/fonts/charter_italic-webfont.eot?#iefix') format('embedded-opentype'),
              url('../Assets/fonts/charter_italic-webfont.woff') format('woff'),
              url('../Assets/fonts/charter_italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'charterregular';
         src: url('../Assets/fonts/charter_regular-webfont.eot');
         src: url('../Assets/fonts/charter_regular-webfont.eot?#iefix') format('embedded-opentype'),
              url('../Assets/fonts/charter_regular-webfont.woff') format('woff'),
              url('../Assets/fonts/charter_regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

//Colors
$orange:#fd5a3c;
$white:#ffffff;
$dark:#282B38;
$bglight:#E5E5E5;
$purple:#3D065F;
$purplelight:#B4289A;

body{
    font-family: "Averta-Regular";
    font-size:16px;
}

.altRow:nth-child(2n+1){
    background-color: #eee;
}

.altRow:last-child{
    background-color: transparent;
}

.cursorpointer{
    cursor: pointer;
}

.cursorpointer:hover{
    opacity: 0.8;
}

@media (max-width: 992px) {
    .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        width: 75%;
        height: 100%;
        background:#610699;
    }

    .navbar-collapse.collapsing {
        left: -75%;
        transition: height 0s ease;
    }

    .navbar-collapse.show {
        left: 0;
        transition: left 300ms ease-in-out;
    }
    .navbar-toggler.collapsed ~ .navbar-collapse {
        transition: left 500ms ease-in-out;
    }

    .offcanvaslogo{
        text-align: center;
        padding:1em 0;
    }
}


header{
	background:$purple;
    .navbar{
        transition: 0.2s;
        .navbar-brand{
            @include lg{
                text-align: center;
                //width:250px;
            }
            img{
                max-width:80%;
                transition: 0.4s;
                @include lg{
                    max-width:80%;
                }
            }
        }
        .navbar-nav{
            .nav-item{
                padding:.5em 0;
                font-weight: 400;
                font-size:.9em;
                padding:0 .5em;
                @include xxl{
                    padding:0 2em 0 2em;
                }
                a{
					color:#FEDDFF;
                    text-align: center;
                    @include lg{
                        border:none;
                    }
					&.contactbtn{
                        color:$orange;
                        font-weight:bold;
                        font-size:1em;
                        border:1px solid $orange;
                        text-align: right;
                        line-height: 1em;
				    }
                    &:hover{
                        color:$white;
                    }
                  
                }
                &:last-child{
                    a{
                        border:none;
                        font-weight:bold;
                        padding:.7em 0;
                    }
                }
                .demobtn{
                    background:$purplelight;
                    border-radius: .2em;
                }
                &.active{
                    border-bottom: 2px solid #B4289A;
                }
            }           
        }
    }
    &.slide-in{
        .navbar{
            padding-top: 0.5em!important;
            .navbar-brand{
                img{
                    max-width:50%;
                }
            }
        }
    }
}

.navbar-dark .navbar-toggler {
    color: rgba(202, 84, 238, 0.5);
    /* border-color: rgba(255, 255, 255, 0.1); */
}

.herobanner{
	background:$purple;
    padding-top:105px;
    padding-bottom:75px;
    min-height:500px;
    .left{
        padding:50px 0 0 0;
        @include lg{
            padding:80px 0 0 0;
        }
        @include xxl{
            padding:100px 0 0 0;
        }
    }
	h1{
		font-size:2em;
		font-weight:300;
		color:$white;
        font-family: "charterregular";
        @include xl{
            font-size:2.2em;
        }
        @include xxl{
            font-size:2.8em;
        }
	}
	p{
		font-weight:500;
		color:#FEDDFF;
        line-height:auto;
	}
	.btn{
		background:#B4289A;
		color:$white;
		border:none;
		font-weight:bold;
		font-size:.9em;
        z-index:0;
        position: relative;
        /*
        &::before{
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:$p;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 100%;
            transform-origin: 0% 100%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: .2s;
            transition-duration: .2s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }*/
        &:hover{
            color:$gray-900;
            &::before{
                 transform: scaleX(1);
            }
        }
	}
    .banner{
        //min-height:550px;
        .carousel{
          //  min-height:80vh;
          overflow: visible;
        }
    }
    .carousel-indicators{
        bottom:-50px;
        li{
            border-radius: 50px;
            width:10px;
            height:10px;
            border:none;
            background-color: #B4289A;
        }
    }
    .carousel-control-prev,.carousel-control-next{width:auto;}
}

.marketing{
    background:$dark;
    .welcometxt{
        h4{
             color:#fd5a3c;
             font-size:1em;
             font-weight:bold;
        }
        h2{
             font-family:"charterbold";
             font-weight:bold;
             font-size:1em;
             color:#fcfcfc;
             @include lg{
                 font-size:3em;
             }
        }
        p{
            font-weight:normal;
        }
    }
    .features{
        .services{
            z-index:1;
            position: relative;
            cursor: pointer;
            color:$gray-500;
             &::before{
                 content: "";
                 position: absolute;
                 z-index: -1;
                 top: 0;
                 left: 0;
                 right: 0;
                 bottom: 0;
                 background:#fd5a3c;
                 -webkit-transform: scaleX(0);
                 transform: scaleX(0);
                 -webkit-transform-origin: 0 100%;
                 transform-origin: 0% 100%;
                 -webkit-transition-property: transform;
                 transition-property: transform;
                 -webkit-transition-duration: .2s;
                 transition-duration: .2s;
                 -webkit-transition-timing-function: ease-out;
                 transition-timing-function: ease-out;
        }
        &:hover{
            color:$white;
            padding-left:20px;
            &::before{
                 transform: scaleX(1);
            }
        }
        h4{
            color:#fd5a3c;
                font-size:1em;
                font-weight:bold;
                text-align:center
        }
        h5{
         padding:.8em 0;
         font-size:1em;
         margin:0;
         font-weight: bold;
     }
     }
    }
}

.homesection{
    h2{
        font-family: "charterregular";
        text-align: center;
        font-weight:regular;
        font-size:2.5em;
        color:$purple;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 3px;
            width: 7%;
            border-bottom: 3px solid magenta;
        }
    }
    .fig{
        display: inline-block;
        background:$gray-200;
        padding:1em;
        cursor: pointer;
    }
    img{
        max-width: 100%;;
    }
    .carousel{
        min-height: 400px;
    }
    .carousel-indicators{
        bottom:-50px;
        li{
            border-radius: 50px;
            width:10px;
            height:10px;
            border:none;
            background-color: #B4289A;
        }
    }
    &.testimonials{
        background-color: #3D065F;
        color: #fff;
        h2{
            color:#fff;
        }
        .carousel{
            min-height: 300px;
        }
        .carousel-indicators{
           bottom:0;
        }
    }
    &.clients{
        background-color: #F0F1F3;
        .carousel{
            min-height: 300px;
        }
        .carousel-indicators{
           bottom:0;
        }
    }
}


.solutions{
    .herobanner{
       min-height:500px;
    }
    .accordion{
        .card{
            border:1px solid #cccccc;
            border-width: 0px 0px 1px 0px;
            border-radius:0;
            background:none;
            .card-header{
                font-size:1.3em;
                cursor:pointer;
                background: none;
                border-bottom:none;
            }
        }
    }
}

.about{
    .herobanner{
        min-height:550px;
     }
     .aboutus{
        min-height:400px;
     }
     .vision{
        background: url("../Assets/images/vision.png") #f2f2f2 no-repeat;
        background-size: contain;
        min-height:800px;
        @include lg{
            min-height:500px;
        }
        background-position: -100px bottom;
     }
     .innovation{
        background: #3D065F ;
        color:$white;
        min-height:500px;
     }
}

.resources{
    .herobanner{
        min-height:500px;
     }
     img{max-width: 100%;;}
}

.careers{
    .herobanner{
        min-height:500px;
     }
     .careercontent{
        background:url("../Assets/images/careerimg.jpg") #fafafa no-repeat;
        background-size: contain;
        background-position: right;
     }
}

.projects{
    margin-top:85px;
    img{
        max-width:100%;
        border:12px solid $gray-300;
    }
}

.pricing{
    h3{
        color:$white;
    }
    thead{
        background: #B4289A;
        text-align: center;
        font-size:1.5em;
        color:$white;
        position: relative;
        font-family: "charterbold";
        &::after{
            content:'';
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-top: 12px solid #B4289A;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
    }
    }
    .price{
        background:#651795;
        text-align: center;
        font-size:2em;
        color:$white;
        border-top:3px solid #4C0976;
        font-family: "charterbold";
    }
    .details{
        background:#4C0976;
        color:$white;
        font-size:.9em;   
        ul{
         padding:20px 0 0 30px;
        }
        .fade{
            opacity: 0.3;
          }
          .btn{
            background:transparent;
            padding:0;
          }
    }
    .CTA{
        background:#4C0976;
    }
    .info{
        color:$white;
        font-size:1.25em;
    }
    .faq{
        h3{color:#000;}
    .question{
        font-size:1.25em;
    }
    .accordion{
        .card{
            border:1px solid #ccc;
            border-width: 0px 0px 1px 0px;
            border-radius:0;
            background:none;
            .card-header{
                font-size:1.3em;
                cursor:pointer;
            }
        }
    }
}
}

.contact{
    .herobanner{
        min-height:450px;
    }
    button{
        background:#610699;
        color:$white;
        font-weight:bold;
        font-size:1em;
        border:none;
        &:hover{
            background:$purple;
        }
    }
    textarea,input{border:1px solid #6219AD;}
    .success{
        color:#3D065F;
    }
}

footer{
    background:#3D065F;
    padding:3em 0;
    color:$white;
    font-size:.9em;
    img{
        max-width:100%;
    }
    .purple{
        color:#BF2BBF;
        &:hover{
            color:#B4289A;
        }
    }
    a{
        color:$gray-500;
        &:hover{
            color:$gray-500;
        }
    }
    h2{
        padding:0 0 .5em 0;
    }
    .fnav{
        color:$white!important;
        &:hover{
            color:$gray-500!important;
            text-decoration: none;
        }
    }
    h5{
        font-size:1em;
        font-weight:bold;
    }

    ul{
        list-style:none;
        a{
            color:$gray-500;
            &:hover{
                color:$gray-500;
                text-decoration: none;
            }
        }
    }
    .social{
        flex-direction: row;
        &__list{
            background:none;
            font-size:1.5em;
            width:auto;
            &:hover{
                color:#B4289A;
                background:none;
            }
            
        }
    }
    .copyright{
        li:first-child{
            border-right:1px solid $white;
            padding-right:1em;
            margin-right:1em;
        }
    }
}
